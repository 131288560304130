import { ArticlePreview } from 'interfaces/content/articles/Articles';
import Row from 'components/Grid/Row';
import classNames from 'classnames';
import { setEventAction } from 'utils/trackDataUtils';
import Link from 'components/Globals/Base/Link';
import WellHomePageInStream from 'components/Ad/AdSlot/ProductHomePageInStream';
import ArticleCard from 'components/Globals/ArticleCard';
import FeaturedArticle from 'components/Home/FeaturedArticle';
import { getDevicePixelRatio } from 'use-device-pixel-ratio';
import * as S from './FeaturedSecondaryStyles';

export interface FeaturedSecondaryProps {
  className?: string;
  heading?: string;
  headingLink?: string;
  dataCy?: string;
  featuredArticle?: ArticlePreview;
  articles: ArticlePreview[];
  hideAds?: boolean;
}

const FeaturedSecondary = ({
  className,
  heading,
  featuredArticle,
  articles,
  headingLink,
  dataCy,
  hideAds,
}: FeaturedSecondaryProps) => {
  if (!featuredArticle && articles.length < 1) {
    return null;
  }

  const isEuropeFinanceSection = heading === 'Finance';
  const dpr = getDevicePixelRatio({ round: false }) >= 2.125;
  const fontSize = dpr ? 'level4' : 'level3';

  return (
    <S.Wrapper
      data-cy={dataCy}
      className={classNames(className, {
        noHeading: !heading,
      })}
    >
      {heading && (
        <S.Title
          className={`${featuredArticle ? '' : 'noFeatured'}`}
          as='h2'
          $fontFamily='saol'
          $size={{ default: 'level3', lgMin: 'level2' }}
        >
          {headingLink ? (
            <Link
              href={headingLink}
              type='primary'
            >
              {heading}
            </Link>
          ) : (
            heading
          )}
        </S.Title>
      )}
      {featuredArticle && (
        <S.Featured>
          <FeaturedArticle {...featuredArticle} />
        </S.Featured>
      )}
      <S.GridWrapper>
        <Row>
          {articles.map((article) => (
            <li key={article.title}>
              <ArticleCard
                article={article}
                eyebrowType='red'
                variant='hero'
                titleSize={{ default: fontSize }}
                trackingData={{
                  contentPlacementCD: article.eyebrowSectionLabel,
                  eventAction: setEventAction(article.eyebrowSectionLabel, article.eyebrowTagLabel),
                  eventCategory: 'article',
                  eventLabel: article.title,
                }}
              />
            </li>
          ))}
        </Row>
      </S.GridWrapper>
      {!hideAds && isEuropeFinanceSection && <WellHomePageInStream id={0} />}
    </S.Wrapper>
  );
};

export default FeaturedSecondary;
