import BylineList from 'components/Byline';
import Link from 'components/Globals/Base/Link';
import { NextCustomImage } from 'components/Image';
import { ArticlePreview } from 'interfaces/content/articles/Articles';
import { setEventAction } from 'utils/trackDataUtils';
import parseCaptionWhenAltTextEmpty from 'utils/parsingUtils/clientSideParsing';
import { getDevicePixelRatio } from 'use-device-pixel-ratio';
import * as S from './FeaturedArticleStyles';

interface FeaturedArticleProps extends ArticlePreview {
  isFirstPrimary?: boolean;
}

const FeaturedArticle = ({
  eyebrowSectionLabel,
  eyebrowSectionLink,
  eyebrowTagLabel,
  title,
  titleLink,
  excerpt,
  authors,
  date,
  image,
  isFirstPrimary,
}: FeaturedArticleProps) => {
  const trackingData = {
    contentPlacementCD: 'hero',
    eventAction: setEventAction(eyebrowSectionLabel, eyebrowTagLabel),
    eventCategory: 'article',
    eventLabel: `title click : ${title}`,
  };
  const recommendsArticle = titleLink.match(/recommends/g);
  const dpr = getDevicePixelRatio({ round: false }) >= 2.125;
  const fontSize = dpr ? 'level3' : 'level2';

  return (
    <S.Wrapper className={isFirstPrimary ? 'first-primary' : ''}>
      <S.ImageWrapper className='image'>
        <Link
          href={titleLink}
          trackingData={{ ...trackingData, eventLabel: `banner click : ${title}` }}
          ariaLabel={`Go to featured article ${title}`}
          type='image'
          className='image-link'
        >
          <NextCustomImage
            src={image.mediaItemUrl}
            alt={parseCaptionWhenAltTextEmpty(image)}
            width='720'
            height='480'
            priority={isFirstPrimary}
          />
        </Link>
      </S.ImageWrapper>
      <S.TextWrapper className='text'>
        {eyebrowSectionLabel !== 'No Primary Section Found' && (
          <S.Section>
            <Link
              href={eyebrowSectionLink}
              trackingData={{ ...trackingData, eventLabel: `category click : ${title}` }}
              ariaLabel={`Go to featured article's section ${eyebrowSectionLabel}`}
              className='title-link'
              type='red'
            >
              {eyebrowSectionLabel}
            </Link>
          </S.Section>
        )}
        <S.Title
          as='h2'
          $fontFamily='saol'
          $size={{ default: 'level3', lgMin: 'level1', mdMin: fontSize }}
        >
          <Link
            href={titleLink}
            trackingData={trackingData}
            ariaLabel={`Read the featured article titled ${title}`}
            type='primary'
            className='title-link'
          >
            {title}
          </Link>
        </S.Title>
        <S.Excerpt>
          <Link
            href={titleLink}
            trackingData={trackingData}
            ariaLabel={`Read the full article titled ${title}`}
            type='primary'
            className='excerpt-link'
          >
            {excerpt}
          </Link>
        </S.Excerpt>
        {authors.length !== 0 && (
          <S.ByLineListWrapper>
            <BylineList
              bylineList={authors}
              trackingData={{ ...trackingData, eventLabel: title }}
            />
          </S.ByLineListWrapper>
        )}
        {!recommendsArticle && <S.Publishdate>{date}</S.Publishdate>}
      </S.TextWrapper>
    </S.Wrapper>
  );
};

export default FeaturedArticle;
