import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: none !important;
  margin-top: 8px;

  @media (${breakpoint.mdMin}) {
    margin-top: 0px;
  }

  @media (${breakpoint.lgMin}) {
    &.noHeading {
      margin-top: 0;
    }

    margin-top: 48px;
  }

  @media (-webkit-device-pixel-ratio: 2.125) and (${breakpoint.mdMin}) and (${breakpoint.lgMax}){
    &.noHeading {
      margin-top: 0;
    }
    margin-top: 48px;
  }

  &.homepage-block {
    margin-top: 40px;

    &.noHeading {
      margin-top: 0px;
    }
    & > .heading.no-featured {
      margin-top: 0px;
    }

    @media (${breakpoint.mdMin}) {
      margin-top: 48px;
    }
  }
`;

export const Title = styled(Heading)`
  border-bottom: ${getColor('borderBlack')} 1px solid;
  margin-left: 16px;
  margin-right: 16px;
  text-align: left;

  @media (${breakpoint.mdMin}) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (${breakpoint.lgMin}) {
    margin-left: 48px;
    margin-right: 48px;
    margin-bottom: 0;
  }

  @media (-webkit-device-pixel-ratio: 2.125) and (${breakpoint.mdMin}) and (${breakpoint.lgMax}) {
    margin-bottom: 0;
  }

  &.noFeatured {
    margin-bottom: 0;
    margin-top: 40px;
    padding-bottom: 4px;

    @media (${breakpoint.mdMin}) {
      margin-top: 48px;
      margin-bottom: 0;
    }

    @media (${breakpoint.lgMin}) {
      margin-bottom: 24px;
    }

    @media (-webkit-device-pixel-ratio: 2.125) and (${breakpoint.mdMin}) and (${breakpoint.lgMax}) {
      margin-bottom: 24px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  a {
    color: inherit;
  }
`;

export const Featured = styled.div`
  margin-top: 24px;
  width: 100%;

  @media (${breakpoint.mdMin}) {
    margin-top: 0;
  }
`;

export const GridWrapper = styled.div`
  margin: 24px 16px 0px;
  max-width: none;

  @media (${breakpoint.smMin}) {
    margin-top: 0px;
  }

  @media (${breakpoint.mdMin}) {
    margin: 0 24px 0px;
  }

  @media (${breakpoint.lgMin}) {
    padding: 0 24px;
  }

  @media (-webkit-device-pixel-ratio: 2.125) and (${breakpoint.mdMin}) and (${breakpoint.lgMax}) {
    padding: 0px;
    margin: 0px;
  }

  @media (${breakpoint.xlMin}) {
    margin: 0 24px 0px;
    padding: 0 24px;
  }
`;
